.navb {
    position: relative;
}

.link {
    size: 10px;
}

.navbar-color {
    background-color: rgb(53, 25, 51);
}