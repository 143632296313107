.card-img-top {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.card-img {
    height: 50%;
    width: auto;
    object-fit: contain;
}


.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    top: 5%;
    left: 5%;
    height: 90%;
    width: 90%;
    position: absolute;
    margin: auto
}

.top-padding {
    top: 10%;
    position: absolute
}

.blocktext {
    margin: 0;
    position: absolute;
    top: 15%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.parallel-buttons {
    left: 10%;
    position: absolute;
}

.parallel-buttons-2 {
    left: 10%;
    position: absolute;
}

.page-buttons {
    position: absolute;
    top: 120%;
    left: 45%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.bottom-align {
    
    top: 75%;
    position: absolute;
      
}

.card-img-small {
    height: 50%;
    width: auto;
    object-fit: contain;
}
