.chart {
    width: 66%;
    height: 33%;
    margin: 0 auto;
}

.pie-chart {
    width: 33%;
    height: 33%;
    margin: 0 auto;
}